<template>
    <section>
        <modal ref="modalEditarCupo" titulo="Editar cupo" icon="online-shop" no-aceptar adicional="Asignar" @adicional="guardar">
            <ValidationObserver ref="validacion">
                <div class="row mx-0 justify-center">
                    <div class="col-12 pl-4 text-general f-13">
                        Cupo del credito
                    </div>
                    <div class="col mb-4">
                        <template v-if="!model.ilimitado">
                            <ValidationProvider v-slot="{errors}" rules="required" name="Cupo del credito">
                                <money v-model="model.cupo" class="input-money w-100" v-bind="money" />
                                <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                            </ValidationProvider>
                        </template>
                        <template v-else>
                            <el-input placeholder="Valor" disabled size="small" />
                        </template>
                        <el-checkbox v-model="model.ilimitado" class="ml-2" @change="!model.ilimitado == esIlimitado()">
                            Ilimitado
                        </el-checkbox>
                    </div>
                </div>
                <div class="row mx-0 justify-center">
                    <div class="col-12 pl-4 my-2 text-general f-13">
                        Comentario
                    </div>
                    <div class="col mb-4">
                        <ValidationProvider v-slot="{errors}" rules="required|max:300" name="Comentario">
                            <el-input v-model="model.comentario" type="textarea" placeholder="Comentario" class="w-100" :rows="5" maxlength="300" show-word-limit />
                            <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                        </ValidationProvider>
                    </div>
                </div>
            </ValidationObserver>
        </modal>
    </section>
</template>
<script>
import Produccion from "~/services/tiendas/produccion";
import {Money} from 'v-money'
export default {
    components:{
        Money,
    },
    data(){
        return{
            model:{
                cupo: 0,
                comentario: null,
                ilimitado: false,
            },
            money: {
                thousands: '.',
                prefix: '$ ',
                precision: 0,
                masked: false
            },
        }
    },
    computed: {
        id_tienda(){
            return Number(this.$route.params.id_tienda)
        }
    },
    methods: {
        toggle(cupo){
            this.model.cupo = cupo;
            if(parseInt(cupo) == 0){
                this.model.ilimitado = true;
            }
            this.$refs.modalEditarCupo.toggle();
        },
        async guardar(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid)return;
                const { data } = await Produccion.putCupoLeechero(this.id_tienda,this.model);
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.limpiar();
                this.$emit('actualizar');
                this.$refs.modalEditarCupo.toggle();
            } catch (e){
                this.error_catch(e)
            }
        },
        limpiar(){
            this.model.cupo = 0;
            this.model.comentario = null;
            this.model.ilimitado = false;
            this.$refs.validacion.reset();
        },
        esIlimitado(){
            if(this.model.ilimitado){
                return this.model.cupo = 0;
            }
        },
    }
}
</script>
